<template>
  <div class="about">
    <v-card elevation="2" class="sm:tw-p-4 tw-text-justify tw-mb-3" v-for="(information, index) in ALL_INFORMATION" :key="index">
      <v-card-title class="tw-text-lg sm:tw-text-xl tw-p-2.5 sm:tw-p-4">
        {{information.title}}
      </v-card-title>
      <v-card-text class="tw-p-2.5 sm:tw-p-4" v-html="information.description">
        
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: "About",
  computed: mapGetters(['ALL_INFORMATION']),
  mounted(){
    this.getAllInformation()
  },
  methods: {
    ...mapActions(['getAllInformation'])
  }
}
</script>
